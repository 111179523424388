import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography
} from '@mui/material';
import axios from '../network';

const ActivateUser = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  const [state, setState] = useState({
    // user editable, but they'll get edited in formik. underbarred to match server schema.
    first_name: '',
    last_name: '',
    // password: '',  // this doesn't come from the server and exists only in formik
    // component state
    registrationSuccess: false
  });

  useEffect(() => {
    axios
      .get(`/api/auth/confirm-invitation/${token}`)
      .then((response) => {
        setState({
          ...state,
          first_name:
            response.data.result.first_name && response.data.result.first_name,
          last_name:
            response.data.result.last_name && response.data.result.last_name
        });
      })
      .catch(() => {
        // maybe growl?
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Activate Account | Kaart SSO</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          {!state.registrationSuccess ? (
            <Formik
              initialValues={{
                first_name: state.first_name,
                last_name: state.last_name,
                password: ''
              }}
              validationSchema={Yup.object().shape({
                first_name: Yup.string()
                  .max(255)
                  .required('First name is required.'),
                last_name: Yup.string()
                  .max(255)
                  .required('Last name is required.'),
                password: Yup.string()
                  .max(255)
                  .required('Password is required.')
              })}
              onSubmit={(values, { setSubmitting }) => {
                axios
                  .post(`/api/auth/confirm-invitation/${token}`, values)
                  .then(() => {
                    setState({ ...state, registrationSuccess: true });
                    navigate('/login', { replace: true });
                  })
                  .catch(() => {
                    setSubmitting(false);
                  });
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box sx={{ mb: 3 }}>
                    <Typography color="textPrimary" variant="h2">
                      Activate Account
                    </Typography>
                  </Box>
                  <TextField
                    error={Boolean(touched.first_name && errors.first_name)}
                    fullWidth
                    helperText={touched.first_name && errors.first_name}
                    label="First Name"
                    required
                    margin="normal"
                    name="first_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.first_name}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.last_name && errors.last_name)}
                    fullWidth
                    helperText={touched.last_name && errors.last_name}
                    label="Last Name"
                    required
                    margin="normal"
                    name="last_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.last_name}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Password"
                    required
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />
                  <Box sx={{ py: 2 }}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Activate Account
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          ) : (
            <Typography color="textSecondary" variant="body1">
              Thank you for activating your account!
              <br />
              <Link component={RouterLink} to="/login" variant="h4">
                Click here to log in.
              </Link>
            </Typography>
          )}
        </Container>
      </Box>
    </>
  );
};

export default ActivateUser;

import { Link as RouterLink } from 'react-router-dom';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography
} from '@mui/material';
import axios from '../network';

const ResetPasswordRequest = () => {
  const [state, setState] = useState({
    requestSuccess: false
  });

  return (
    <>
      <Helmet>
        <title>Reset Password Request | Kaart SSO</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          {!state.requestSuccess ? (
            <Formik
              initialValues={{
                email: ''
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email('Must be a valid email.')
                  .max(255)
                  .required('Email is required.')
              })}
              onSubmit={(values, { setSubmitting }) => {
                axios
                  .post('/api/auth/reset-password', values)
                  .then(() => {
                    setState({ ...state, requestSuccess: true });
                  })
                  .finally(() => {
                    setSubmitting(false);
                  });
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box sx={{ mb: 3 }}>
                    <Typography color="textPrimary" variant="h2">
                      Reset password
                    </Typography>
                  </Box>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email Address"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                  <Link component={RouterLink} to="/login" variant="h6">
                    Remembered password?
                  </Link>
                  <Box sx={{ py: 2 }}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Request Password Reset
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          ) : (
            <Typography color="textSecondary" variant="body1">
              Please check your email for the password reset link.
            </Typography>
          )}
        </Container>
      </Box>
    </>
  );
};

export default ResetPasswordRequest;

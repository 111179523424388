import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@mui/material';
import axios from '../network';

const ActivatePrimeUser = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  const [state, setState] = useState({
    errorMessage: ''
  });

  useEffect(() => {
    axios
      .post(`/api/auth/activate-prime-user/${token}`)
      .then(() => {
        navigate('/login', { replace: true });
      })
      .catch((error) => {
        const message = error.response.data.message
          ? error.response.data.message
          : 'There was an error with the authentication server.';
        setState({ ...state, errorMessage: message });
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Activate Account | Kaart SSO</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          Activating your account...
          <br />
          {state.errorMessage}
        </Container>
      </Box>
    </>
  );
};

export default ActivatePrimeUser;

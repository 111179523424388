import { useContext, useEffect, useState } from 'react';
import { RegistrationContext } from 'src/contexts/Registration.js';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography
} from '@mui/material';
import axios from '../network';
import { integrations } from 'src/utils/constants';
const RegistrationForm = () => {
  // const { state, setState } = useContext(RegistrationContext);
  const [state, setState] = useState({
    registrationSuccess: false
  });

  // let blurb =
  //   (state.registrationType === 'company'
  //     ? 'Use this only to create a totally new company.'
  //     : 'Create an individual account with no relation to any company.') +
  //   ' If your company is already using Kaart services, you should request an invite from your administrator.';
  // let initialValues = {
  //   type: state.registrationType,
  //   integrations: state.registrationIntegrations,
  //   email: '',
  //   first_name: '',
  //   last_name: '',
  //   password: ''
  // };
  // // if registering a user, add 'company_name' to initial values
  // if (state.registrationType === 'company') {
  //   initialValues['company_name'] = '';
  // }

  return (
    <Container maxWidth="sm">
      {!state.registrationSuccess ? (
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email('Must be a valid email.')
              .max(255)
              .required('Email is required.'),
            first_name: Yup.string()
              .max(255)
              .required('First name is required.'),
            last_name: Yup.string().max(255).required('Last name is required.'),
            password: Yup.string().max(255).required('Password is required.'),
            company_name: Yup.string().when('type', {
              is: 'company',
              then: Yup.string().max(255).required('Company name is required.')
            })
          })}
          onSubmit={(values, { setSubmitting }) => {
            axios
              .post('/api/auth/register', values)
              .then(() => {
                setState({ ...state, registrationSuccess: true });
              })
              .catch(() => console.error('An error occured'))
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ mb: 3 }}>
                <Typography color="textPrimary" variant="h2">
                  Register new {state.registrationType}
                </Typography>
                <Typography color="textSecondary" gutterBottom variant="body2">
                  {/* {blurb} */}
                </Typography>
              </Box>
              <TextField
                error={Boolean(touched.first_name && errors.first_name)}
                fullWidth
                helperText={touched.first_name && errors.first_name}
                label="First Name"
                margin="normal"
                name="first_name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.first_name}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.last_name && errors.last_name)}
                fullWidth
                helperText={touched.last_name && errors.last_name}
                label="Last Name"
                margin="normal"
                name="last_name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.last_name}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label="Email Address"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="Password"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
              />
              {state.registrationType === 'company' && (
                <TextField
                  error={Boolean(touched.company_name && errors.company_name)}
                  fullWidth
                  helperText={touched.company_name && errors.company_name}
                  label="Company Name"
                  margin="normal"
                  name="company_name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.company_name}
                  variant="outlined"
                />
              )}
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Sign up now
                </Button>
                <Button
                  color="primary"
                  fullWidth
                  size="large"
                  onClick={() => setState({ ...state, registrationType: null })}
                >
                  Go Back
                </Button>
              </Box>

              <Typography color="textSecondary" variant="body1">
                Have an account?{' '}
                <Link component={RouterLink} to="/login" variant="h6">
                  Sign in
                </Link>
              </Typography>
            </form>
          )}
        </Formik>
      ) : (
        <>
          <Typography color="textSecondary" variant="body1">
            Thank you for registering! Please check your email to activate your
            account.
          </Typography>
          <Typography color="textSecondary" align="center" variant="body1">
            <Link component={RouterLink} to="/login" variant="h6">
              Sign in
            </Link>
          </Typography>
        </>
      )}
    </Container>
  );
};

const Register = () => {
  const { state, setState } = useContext(RegistrationContext);
  const [integration, setIntegration] = useState();
  const [registrationMethod, setRegistrationMethod] = useState();
  useEffect(() => {
    let registrationIntegrations = new URLSearchParams(
      window.location.search
    ).get('integrations');
    let inRegistrationMethod = new URLSearchParams(window.location.search).get(
      'method'
    );

    setIntegration(registrationIntegrations);
    setRegistrationMethod(inRegistrationMethod);
    console.log(registrationMethod);
    // setState({
    //   ...state,

    //     ? new URLSearchParams(window.location.search)
    //         .get('integrations')
    //         .split(',')
    //     : []
    // });
  }, []);

  var testCheck = true;
  return (
    <>
      <Helmet>
        <title>Register | Kaart SSO</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          {/* !state.registrationSuccess */}
          {testCheck ? (
            <Formik
              initialValues={{
                // underbarred to match server schema.
                email: '',
                first_name: '',
                last_name: '',
                password: '',
                company_name: ''
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email('Must be a valid email.')
                  .max(255)
                  .required('Email is required.'),
                first_name: Yup.string()
                  .max(255)
                  .required('First name is required.'),
                last_name: Yup.string()
                  .max(255)
                  .required('Last name is required.'),
                password: Yup.string()
                  .max(255)
                  .required('Password is required.'),
                company_name: Yup.string()
                  .max(255)
                  .required('Company name is required.')
              })}
              onSubmit={(values, { setSubmitting }) => {
                console.log(values);
                axios
                  .post(
                    '/api/auth/register'.concat(
                      `?method=${registrationMethod}&integrations=${integration}`
                    ),
                    values
                  )
                  .then(() => {
                    setState({ ...state, registrationSuccess: true });
                  })
                  .finally(() => {
                    setSubmitting(false);
                  });
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box sx={{ mb: 3 }}>
                    {registrationMethod === 'admin' ? (
                      <Typography color="textPrimary" variant="h2">
                        Register New Company
                      </Typography>
                    ) : (
                      <Typography
                        color="textPrimary"
                        variant="h2"
                        style={{ textAlign: 'center' }}
                      >
                        Register as user
                      </Typography>
                    )}
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                      style={{ textAlign: 'center' }}
                    >
                      Sign up to use Viewer as part of an already registered
                      organization.
                      <br />
                      Enter the name of your organization in the "Organization
                      Name" field below.
                      <br />
                      (case sensitive)
                    </Typography>
                  </Box>
                  <TextField
                    error={Boolean(touched.first_name && errors.first_name)}
                    fullWidth
                    helperText={touched.first_name && errors.first_name}
                    label="First Name"
                    margin="normal"
                    name="first_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.first_name}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.last_name && errors.last_name)}
                    fullWidth
                    helperText={touched.last_name && errors.last_name}
                    label="Last Name"
                    margin="normal"
                    name="last_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.last_name}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email Address"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Password"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.company_name && errors.company_name)}
                    fullWidth
                    helperText={touched.company_name && errors.company_name}
                    label="Organization Name"
                    margin="normal"
                    name="company_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.company_name}
                    variant="outlined"
                  />
                  <Box sx={{ py: 2 }}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Sign up now
                    </Button>
                  </Box>
                  <Typography color="textSecondary" variant="body1">
                    Have an account?{' '}
                    <Link component={RouterLink} to="/login" variant="h6">
                      Sign in
                    </Link>
                  </Typography>
                </form>
              )}
            </Formik>
          ) : (
            <Typography color="textSecondary" variant="body1">
              Thank you for registering! Please check your email to activate
              your account.
            </Typography>
          )}
        </Container>
      </Box>
    </>
  );
};

export default Register;

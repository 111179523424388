import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography
} from '@mui/material';
import axios from '../network';

const RegisterUser = () => {
  const [state, setState] = useState({
    registrationSuccess: false
  });

  const queryParams = new URLSearchParams(window.location.search);
  const current_integration = queryParams.get('integrations');
  return (
    <>
      <Helmet>
        <title>Register | Kaart SSO</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          {!state.registrationSuccess ? (
            <Formik
              initialValues={{
                // underbarred to match server schema.
                email: '',
                first_name: '',
                last_name: '',
                password: '',
                headers: {
                  'Content-Type': 'application/json',
                  mode: 'cors'
                },
                integration: current_integration
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email('Must be a valid email.')
                  .max(255)
                  .required('Email is required.'),
                first_name: Yup.string()
                  .max(255)
                  .required('First name is required.'),
                last_name: Yup.string()
                  .max(255)
                  .required('Last name is required.'),
                password: Yup.string()
                  .max(255)
                  .required('Password is required.')
              })}
              onSubmit={(values, { setSubmitting }) => {
                axios
                  .post('/api/auth/register_user', values)

                  .then(() => {
                    setState({ ...state, registrationSuccess: true });
                  })
                  .finally(() => {
                    setSubmitting(false);
                  });
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box sx={{ mb: 3 }}>
                    <Typography color="textPrimary" variant="h2">
                      Register New User
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    ></Typography>
                  </Box>
                  <TextField
                    error={Boolean(touched.first_name && errors.first_name)}
                    fullWidth
                    helperText={touched.first_name && errors.first_name}
                    label="First Name"
                    margin="normal"
                    name="first_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.first_name}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.last_name && errors.last_name)}
                    fullWidth
                    helperText={touched.last_name && errors.last_name}
                    label="Last Name"
                    margin="normal"
                    name="last_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.last_name}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email Address"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Password"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />
                  <Box sx={{ py: 2 }}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Sign up now
                    </Button>
                  </Box>
                  <Typography color="textSecondary" variant="body1">
                    Have an account?{' '}
                    <Link component={RouterLink} to="/login" variant="h6">
                      Sign in
                    </Link>
                  </Typography>
                </form>
              )}
            </Formik>
          ) : (
            <Typography color="textSecondary" variant="body1">
              Thank you for registering! Please check your email to activate
              your account.
            </Typography>
          )}
        </Container>
      </Box>
    </>
  );
};

export default RegisterUser;

import { createContext, useState } from 'react';

export const RegistrationContext = createContext({});

export const RegistrationProvider = (props) => {
  const [state, setState] = useState({
    registrationSuccess: false,
    registrationType: null, // 'company' | 'user'
    registrationIntegrations: [] // ['viewer' | 'simple' | 'micro']
  });

  let value = {
    state,
    setState
  };

  return (
    <RegistrationContext.Provider value={value}>
      {props.children}
    </RegistrationContext.Provider>
  );
};

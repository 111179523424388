import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
// import { styled } from '@mui/material';
import styled from '@emotion/styled';
import { logout, userIsLoggedIn, refreshAuth } from '../utils/auth';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { common } from '@mui/material/colors';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: common,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
}));

const DashboardLayoutWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 64
  // [theme.breakpoints.up('lg')]: {
  //   paddingLeft: 256
  // }
}));

const DashboardLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const DashboardLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  useEffect(() => {
    // the dashboard should only render if you're logged in, so only one time (at component load) we check two things:
    // 1. is there a user object saved in localstoage? this probably means you've still got good credentials
    if (!userIsLoggedIn()) {
      // if you're not logged in, this removes any trace you left behind and redirects to /login.
      // this is 100% clientside and super quick, which is why it's separate from the ajax call below.
      logout();
    }

    // 2. do you still have valid refresh credentials? if so, you can make a refresh request and thus we trust you.
    refreshAuth();

    // now that we know you're legit, we'll refresh the access credentials every 19.5 minutes since the token expires at 20 mins.
    // react will clear this interval if you log out or the dashboard componet gets destroyed.
    const interval = setInterval(() => {
      refreshAuth();
    }, 1170000);
    return () => clearInterval(interval);
  }, []);

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <DashboardSidebar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;

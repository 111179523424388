import { Navigate } from 'react-router-dom';
import { RegistrationProvider } from 'src/contexts/Registration';
import UserList from 'src/pages/UserList';
import Company from 'src/pages/Company';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
import Register from 'src/pages/Register';
import RegisterUser from './pages/Register-User';
import ActivatePrimeUser from './pages/ActivatePrimeUser';
import ActivateUser from './pages/ActivateUser';
import DashboardLayout from './components/DashboardLayout';
import ResetPasswordRequest from './pages/ResetPasswordRequest';
import ResetPasswordConfirm from './pages/ResetPasswordConfirm';
import MainLayout from './components/MainLayout';
import Account from './pages/Account';

const routes = [
  {
    path: '/app',
    element: <DashboardLayout />,
    children: [
      { path: 'integrations', element: <Account /> },
      { path: 'account/:id', element: <Account /> },
      { path: 'users', element: <UserList /> },
      { path: 'company', element: <Company /> },
      { path: '', element: <Navigate to="account/me" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'registerUser', element: <RegisterUser /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: 'password-reset', element: <ResetPasswordRequest /> },
      { path: 'password-reset/:token', element: <ResetPasswordConfirm /> },
      { path: 'activate/:token', element: <ActivateUser /> },
      { path: 'activate-prime-user/:token', element: <ActivatePrimeUser /> },
      { path: '/', element: <Navigate to="/login/" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;

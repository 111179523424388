const Logo = (props) => (
  <img
    alt="Kaart"
    src="/static/logo-white.png"
    width="50px"
    height="50px"
    {...props}
  />
);

export default Logo;

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  IconButton
} from '@mui/material';
import InputIcon from '@mui/icons-material//Input';
import {
  User as UserIcon,
  Users as UsersIcon,
  Briefcase as BriefcaseIcon,
  Map as Map
} from 'react-feather';
import { logout, getUser } from '../utils/auth';
import NavItem from './NavItem';

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const user = getUser();

  const items = [
    {
      href: '/app/account/me',
      icon: UserIcon,
      title: 'My Account'
    }
  ];

  if (user && user.role === 'admin') {
    items.push({
      href: '/app/users',
      icon: UsersIcon,
      title: 'Users'
    });
    items.push({
      href: '/app/company',
      icon: BriefcaseIcon,
      title: 'Company'
    });
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Typography color="textPrimary" variant="h3">
          {user ? user.company.name : ''}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
          {/*
          I added a seperate array of Icons for the kaart apps,
           this is because of the proxy 
           that lives in the package json file. 
           Any request that comes from the href prop is proxied 
           using localhost:5000 so we can't use the href prop*/}
          {/* {externalApps.map((item) => (
            <NavItem
            onClick={()=> {
              window.location.replace("https://mikro.kaart.com/")
            }}
            href={''}
            key={item.title}
            title={item.title}
            icon={item.icon}
            />
          ))} */}
        </List>
        <Hidden lgUp>
          <IconButton
            style={{ position: 'absolute', bottom: 0, marginBottom: '3rem' }}
            color="inherit"
            onClick={() => logout()}
          >
            <p
              style={{
                fontSize: 18,
                fontWeight: 'medium',
                textTransform: 'none',
                marginRight: '1rem'
              }}
            >
              Logout
            </p>
            <InputIcon />
          </IconButton>
        </Hidden>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default DashboardSidebar;
